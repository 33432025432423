import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function App() {
  return (
    <Router>
      <div style={{ height : "100vh", background : 'yellow' }}>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/dashboard">Dashboard</Link>
        </li>
      </ul>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        </Switch>
        </div>
    </Router>
  );
}

const Home = () => (
  <div>
    <h1>Home Component</h1>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam quis bibendum
      ante. In scelerisque, arcu non convallis molestie, tortor felis venenatis
      mi, sed blandit nisi tortor vitae velit. Vivamus et est mattis odio
      fermentum egestas. Quisque vel dolor iaculis, faucibus orci id, mollis
      dui. Aliquam at tellus porttitor nunc vestibulum dignissim. Sed ut odio
      hendrerit, fermentum velit quis, pretium est. Vestibulum placerat libero
      nunc, sed hendrerit turpis luctus eu. Etiam tincidunt vel neque nec
      ultricies.
    </p>
  </div>
);

const About = () => (
  <div>
    <h2>About Component</h2>
    <p>
      Ut commodo dapibus nulla, auctor sagittis tellus porta ut. Integer
      pulvinar, elit ac facilisis feugiat, diam lacus maximus turpis, tristique
      aliquam leo dolor eu sapien. Mauris mattis egestas ligula, in imperdiet
      eros ornare non. Curabitur porttitor, est ut suscipit efficitur, elit
      lacus consectetur velit, in luctus nulla justo nec est. Sed venenatis dui
      justo, vel consequat magna ultrices et.
    </p>
  </div>
);

const Dashboard = () => (
  <div>
    <h1>Dashboard</h1>
    <p>
      Mauris sit amet velit sit amet eros pellentesque bibendum vel sit amet
      augue. Curabitur scelerisque placerat lorem cursus pretium. Aliquam vitae
      vulputate lacus. Sed vestibulum ut ante vulputate fringilla. Phasellus eu
      purus id enim sodales pharetra. Vestibulum condimentum dictum magna, a
      fringilla dolor rhoncus sed. Maecenas pretium lacus et ipsum tempus, nec
      gravida nunc pellentesque. Praesent vitae nisl sed turpis bibendum
      porttitor. Proin posuere dui turpis, eu mollis erat posuere volutpat.
      Donec finibus ultrices nulla id pharetra. Curabitur eu nunc risus. Integer
      sit amet pulvinar nibh, a porta tellus. In aliquam ligula nisi, nec
      scelerisque dolor maximus aliquam. Donec suscipit sapien est, id efficitur
      mauris finibus sed. Fusce facilisis ipsum vel felis dignissim, in
      ultricies velit dictum. Donec non purus sed enim posuere tincidunt. Aenean
      eget sem gravida, lobortis nulla sed, ultricies risus. Orci varius natoque
      penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec
      aliquet lectus non pellentesque laoreet. Nulla egestas eleifend euismod.
      Proin sed tellus lobortis, facilisis tellus in, dapibus erat. Sed tempor,
      leo sit amet ultrices dictum, metus eros lacinia felis, eu tincidunt lorem
      arcu id tortor. Curabitur id suscipit justo. Proin et libero ac elit
      porttitor fermentum. Ut vitae nunc bibendum, elementum est ac, molestie
      quam. Praesent blandit, arcu non congue iaculis, justo orci blandit est,
      at posuere dolor dui ut magna. Integer vitae velit eu sapien porttitor
      elementum vel lobortis dui. Curabitur turpis risus, vehicula at eros et,
      dignissim congue massa. Nunc at ante massa.
    </p>
  </div>
);

export default App;
